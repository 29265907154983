import type { IImage, UUIDv4 } from '../../company/interfaces/IGeneric'
import type { IFacilityImage } from '../../company/interfaces/IRegion'
import type { ICompany, ISimpleCompanyWithSlug } from '@/modules/CORE/company/interfaces/ICompany'
import type { IGroup, IUser } from '@/modules/CORE/interfaces/IUser'

import type { CorrectiveWorkorderPermissions, PreventiveWorkorderPermissions } from '@/modules/MAINTENANCE/workorders/interfaces/IWorkorder'
import type { RemarkPermissions } from '@/modules/YARD/remarks/interfaces/IRemarks'
import type { EquipmentPermissions } from '@/modules/MAINTENANCE/equipment/interfaces/IEquipment'
import type { DocumentPermissions } from '@/modules/MAINTENANCE/documents/interfaces/IDocument'

export enum FacilityPermissions {
    AddFacility = 'add_facility',
    ChangeFacility = 'change_facility',
    CreateChecklists = 'create_checklists',
    CreateConcepts = 'create_concepts',
    CreateCorrectiveMaintenance = 'create_correctivemaintenance',
    CreateDocument = 'create_document',
    CreateEquipment = 'create_equipment',
    CreateFacility = 'create_facility',
    CreateGroup = 'create_group',
    CreateHours = 'create_hours',
    CreatePreventiveMaintenance = 'create_preventivemaintenance',
    CreateProject = 'create_project',
    CreateRemarks = 'create_remarks',
    CreateSafetyProcess = 'create_safety_process',
    CreateWorkorders = 'create_workorders',
    DeleteConcepts = 'delete_concepts',
    DeleteDocument = 'delete_document',
    DeleteEquipment = 'delete_equipment',
    DeleteFacility = 'delete_facility',
    DeleteHours = 'delete_hours',
    DeleteProject = 'delete_project',
    DeleteSafetyProcess = 'delete_safety_process',
    DeleteWorkorders = 'delete_workorders',
    EditConcepts = 'edit_concepts',
    EditDocument = 'edit_document',
    EditEquipment = 'edit_equipment',
    EditFacility = 'edit_facility',
    EditHours = 'edit_hours',
    EditProject = 'edit_project',
    EditSafetyProcess = 'edit_safety_process',
    EditWorkorders = 'edit_workorders',
    SignWorkoders = 'sign_workorders',
    ViewChecklists = 'view_checklists',
    ViewConcepts = 'view_concepts',
    ViewCorrectiveMaintenance = 'view_correctivemaintenance',
    ViewDocument = 'view_document',
    ViewEquipment = 'view_equipment',
    ViewFacility = 'view_facility',
    ViewFacilityAdminPage = 'view_facility_admin_page',
    ViewHours = 'view_hours',
    ViewPreventiveMaintenance = 'view_preventivemaintenance',
    ViewProject = 'view_project',
    ViewRemarks = 'view_remarks',
    ViewSafetyProcess = 'view_safety_process',
    ViewWorkorders = 'view_workorders',
}

export type AllPermissions =
    | CorrectiveWorkorderPermissions
    | PreventiveWorkorderPermissions
    | DocumentPermissions
    | EquipmentPermissions
    | RemarkPermissions

interface AccessControl {
    layout_module: boolean
    maintenance_module?: boolean
    storage_module: boolean
}

interface StationaryPosition {
    lat: null | number
    lng: null | number
}

export interface IDesign {
    design: string
}

export interface IYard {
    yard: string
}

export interface ICreateFacility {
    build_no_external: string
    build_no_internal: string
    call_sign: string
    classification_system?: string | null // Optional, if added by the backend
    companies: string[] // Assuming company IDs
    selected_company: string // Assuming company ID
    created?: string // Optional, if fixed by backend
    crew: string[] // Assuming IDs of users
    customer_contact: string | null
    data: {
        accessControl: AccessControl
        mondayToken: string
    }
    delivery_date: string // Assuming date-time as string
    description: string
    design: string | null
    guarantee_expiry_date: string | null // Er jo egentlig string i javascript kontekst
    id?: string // Optional, if generated by backend
    image: string | null
    layout_enabled: boolean
    lessors: ISimpleCompanyWithSlug[] // Assuming IDs of companies
    level: string | null
    locality: UUIDv4 | null
    maintenance_enabled: boolean
    manufacturer: { id: string | null }
    mmsi: string | null
    monday_board_id: string
    monitor: any // Can be further defined based on structure
    monitor_id: string | null
    monitor_type: string | null
    monitor_version: string | null
    name: string
    owners: string[] // Assuming IDs of owners
    project_manager?: string // Optional, if to be deprecated
    project_manager_user: string | null
    region: UUIDv4 | null
    stationary_position: StationaryPosition
    support_contact: string | null
    type: string
    yard: string | null
}

export enum GroupPermissions {
    AddGroup = 'add_group',
    ChangeGroup = 'change_group',
    ChangeGroupName = 'change_group_name',
    DeleteGroup = 'delete_group',
    ViewGroup = 'view_group',
}

// * Facilities
export interface IFacility {
    build_no_external: string
    build_no_internal: string
    delivery_date?: Date | null
    guarantee_expiry_date: string | null
    id: UUIDv4
    name: string
    owners: IFacilityOwners[]
    // slug: string // ! JK fjernes når slug er ute :)
    type: FacilityTypeChoices
    groups: IGroup[]
    permissions: FacilityPermissions[]
}
// * Facility types
export interface IFacilityLessor extends IFacility {
    guarantee_expiry_date: string
    id: UUIDv4
    name: string
    owners: IFacilityOwners[]
    type: FacilityTypeChoices
}
export interface IFacilityManufacturer extends IFacility {
    guarantee_expiry_date: string
    id: UUIDv4
    monday_board_id: string
    owners: IFacilityOwners[]
    type: FacilityTypeChoices
}
export interface IFacilityOwned extends IFacility {
    call_sign: string | null
    classification_system: IClassificationSystem | null
    companies?: UUIDv4[]
    connect_dashboard_enabled: boolean
    created: Date
    customer_contact: UUIDv4 | null
    data?: IFacilityData | null
    delivery_date?: Date | null
    description: string
    design: string | null
    guarantee_expiry_date: string | null // Er jo egentlig string i javascript kontekst
    id: UUIDv4
    image: IImage | null
    layout_enabled: boolean | null
    lessors: ISimpleCompanyWithSlug[]
    level: ILevel | null
    locality: UUIDv4 | null
    maintenance_enabled: boolean
    manufacturer: ISimpleCompanyWithSlug | null | string | UUIDv4
    measure_counter: number | null
    mmsi: string | null
    monday_board_id: string
    monitor: any | null
    monitor_id: string | null
    monitor_type: string | null
    monitor_version: null | '4.0' | '5.0'
    monitorstatus: boolean
    name: string
    owners: IFacilityOwners[]
    phase: IFacilityPhases
    project_manager: IUser
    project_manager_user: UUIDv4 | null
    propulsion: string
    region: UUIDv4 | null
    stationary_position: any | null
    support_contact: UUIDv4 | null
    task_counter: number | null
    type: FacilityTypeChoices
    yard: string | null
}
export interface IFacilityMap {
    owners: IFacilityOwners[]
    call_sign: string | null
    description: string
    design: string | null
    id: UUIDv4
    image: IImage | null
    lessors: UUIDv4[]
    manufacturer: UUIDv4 | null
    mmsi: string | null
    name: string
    stationary_position: any | null
    ais_position: any | null
    type: FacilityTypeChoices
    yard: string | null
    build_no_external: string
    build_no_internal: string
    monday_board_status: IMondayStatus | null
}

export interface IMondayStatus {
    board_id: string
    status: string
    last_updated: string
}

export interface IFacilityTimeseries {
    id: UUIDv4
    name: string
    build_no_internal: string
    connect_dashboard_enabled: boolean
    monitor_version: string
    monitor_type: string
    monitor_id: string | null
    last_seen: string | null
    design: string | null
    yard: string | null
}

export type ICompanyFacilityRelation = 'owned-facilities' | 'lessor-facilities' | 'manufactured-facilities'
// * Facility status
export interface IFacilityDataStatus {
    corrective: IFacilityStatusObject | null
    document: IFacilityStatusObject | null
    last: IFacilityStatusObject | null
    preventive: IFacilityStatusObject | null
}
export type IFacilityStatusType = 'corrective' | 'document' | 'last' | 'preventive'
export type IFacilityAPIStatusType = 'corrective' | 'documents' | 'workorders' | 'preventive'

export interface IFacilityStatusObject {
    color?: string
    count?: number
    due?: number
}
export interface IFacilityStatusObject2 {
    color: string
    count: number
    hidden?: boolean
}
export interface IFacilityData {
    accessControl?: IFacilityAccessControl | null
    monday_webhook?: IFacilityMondayWebhook | null
    supportConfig?: IFacilitySupportConfig | null
    support_contact?: string | null
    status?: IFacilityDataStatus
}
export interface IFacilityMondayWebhook {
    change_column_value: IFacilityMondayWebhookItem
    create_item: IFacilityMondayWebhookItem
    create_update: IFacilityMondayWebhookItem
}

export interface IFacilityMondayWebhookItem {
    id: string
    board_id: number
}

export interface IFacilitySupportConfig {
    img: string
    title: string
}

export interface ILevel {
    id: string
    name: string
    created: string | null
    company: ICompany | null
    parent: any
    index: number
}

export interface IFacilityOwners {
    name: string
    id: string
    slug: string // ! JK - slug er deprecated, se om denne kan fjernes!
}

export interface IFacilityListForNotifications {
    id: UUIDv4
    name: string
    locality_name: string
}

export enum FacilityTypeChoices {
    Vessel = 'vessel',
    Fleet = 'fleet',
    BargeSteinsvik = 'barge_steinsvik',
    Warehouse = 'warehouse',
    RAS = 'ras',
    RASGeneral = 'rasgeneral',
    Slakteri = 'slakteri',
    Landanlegg = 'landanlegg',
    Skamik = 'skamik',
    LandanleggAquaOptima = 'landanlegg_aquaoptima',
    StorageFacility = 'storage_facility',
    Sjoanlegg = 'sjoanlegg',
    MekonRingvasker = 'mekon_ringvasker',
    EContainer = 'econtainer',
}

export type IFacilityPhases = 'none' | 'shipyard' | 'transportation' | 'preparation' | 'delivered'

export interface IFacilityAccessControl {
    arrangement_module: boolean
    document_folders: boolean
    layout_module: boolean
    maintenance_folders: boolean
    maintenance_module: boolean
    monitor_module: boolean
    safety_module: boolean
    safety_module_2: boolean
    service_module: boolean
    storage_module: boolean
}

export interface ICrewMember {
    facility: string
    groups: IGroup[]
    id: number
    role: string
    user: IUser
}

export interface IFacilitySimple {
    // [x: string]: number
    name: string
    id: UUIDv4 | null
    maintenance_module?: boolean
    company_name: string
    image_url: string
    image: IFacilityImage
}

export interface ICompanyFacility {
    facilityId: string
    facilityName: string
    companyName: string
    localityName: string
    imageUrl: string
    image: IFacilityImage
    hasLiveData?: boolean
    maintenance_module?: boolean
    lastSeenVerbose?: string
}

export interface ICompanyFacilityByRegion {
    regionName: string
    regionId: UUIDv4
    facilities: ICompanyFacility[]
}
export interface IFacilityGlobalSearch {
    id: UUIDv4
    name: string
    slug: string
    description: string
    build_no_internal: string
    build_no_external: string
    call_sign: string | null
    design: string | null
    yard: string | null
    mmsi: string | null
    company_id: UUIDv4 | null
    company_name: string | null
}

export interface IFacilityName {
    id: UUIDv4
    name: string
    companyId: UUIDv4 | null
}
export interface IFacilityV3 {
    companyName: string
    companyId: UUIDv4 | null
    id: UUIDv4
    image: IImage
    is_leased: boolean
    localityName: string
    name: string
    regionName?: string
}

export interface IFacilitySimpleV4 {
    id: UUIDv4
    name: string
}
export interface IFacilitySimpleV5 {
    id: UUIDv4
    name: string
    maintenance_module_enabled: boolean
}

export interface IFacilityV3 {
    id: UUIDv4
    name: string
    build_no_internal: string
}

export interface IFacilityV4 {
    id: UUIDv4
    name: string
    image_url: string | null
}

export interface IFacilityStatus {
    corrective: IFacilityStatusObject2
    preventive: IFacilityStatusObject2
    documents: IFacilityStatusObject2
    remark?: IFacilityStatusObject2
}

export interface IRelatedObjects {
    workorders: { id: UUIDv4; name: string }[]
    equipment: { id: UUIDv4; name: string }[]
    documents: { id: UUIDv4; name: string }[]
    remarks: { id: UUIDv4; name: string }[]
}

export interface UpdateUserGroups {
    user_id: UUIDv4
    remove_groups: string[]
    add_groups: string[]
}

interface ObjectPermissionFlags {
    all: boolean
    id: UUIDv4
    name: string
    change: boolean
    delete: boolean
    view: boolean
    disabled: boolean
}
interface WorkorderPermissionFlags extends ObjectPermissionFlags {
    sign: boolean
}

interface RemarkPermissionsFlags extends ObjectPermissionFlags {
    close: boolean
    open: boolean
    canMarkAsComplete: boolean
}
export interface ModelPermissionsMap {
    workorder: WorkorderPermissionFlags[]
    correctivemaintenance: WorkorderPermissionFlags[]
    preventivemaintenance: WorkorderPermissionFlags[]
    document: ObjectPermissionFlags[]
    equipment: ObjectPermissionFlags[]
    remark: RemarkPermissionsFlags[]
}
export type PermissionFlags<M extends keyof ModelPermissionsMap> = ModelPermissionsMap[M]
export type AllPermissionFlags =
    | PermissionFlags<'workorder'>
    | PermissionFlags<'correctivemaintenance'>
    | PermissionFlags<'preventivemaintenance'>
    | PermissionFlags<'document'>
    | PermissionFlags<'equipment'>
    | PermissionFlags<'remark'>

export interface IGroupResponse {
    id: number
    name: string
    displayName: string
    display_name: string
    users: number
    permissions: GroupPermissions[]
    isStandard?: boolean
}
export interface IGroupsToShareWith extends IGroupResponse {
    isStandard: boolean
}

export interface BasePayload {
    group_id?: number | string // snake_case cause this is the key the backend reads
    user_id?: UUIDv4
}
export interface accVsTot {
    accessible: number
    total: number
}
export interface accVsTotObjects {
    correctivemaintenance: accVsTot
    preventivemaintenance: accVsTot
    remark: accVsTot
    equipment: accVsTot
    document: accVsTot
}
export interface ShareModelTypeToField {
    document: { documents_and_permissions: { id: UUIDv4; permissions: DocumentPermissions[] }[] }
    equipment: { equipment_and_permissions: { id: UUIDv4; permissions: EquipmentPermissions[] }[] }
    remark: { remarks_and_permissions: { id: UUIDv4; permissions: RemarkPermissions[] }[] }
    corrective: { corrective_and_permissions: { id: UUIDv4; permissions: CorrectiveWorkorderPermissions[] }[] }
    preventive: { preventive_and_permissions: { id: UUIDv4; permissions: PreventiveWorkorderPermissions[] }[] }
}

export interface ChangeModelTypeToField {
    document: { change_documents_and_permissions: { id: UUIDv4; permissions: DocumentPermissions[] }[] }
    equipment: { change_equipment_and_permissions: { id: UUIDv4; permissions: EquipmentPermissions[] }[] }
    remark: { change_remarks_and_permissions: { id: UUIDv4; permissions: RemarkPermissions[] }[] }
    corrective: { change_corrective_and_permissions: { id: UUIDv4; permissions: CorrectiveWorkorderPermissions[] }[] }
    preventive: { change_preventive_and_permissions: { id: UUIDv4; permissions: PreventiveWorkorderPermissions[] }[] }
}
