import { api } from './ApiSettings'
import http from './http'

import type { APIResponse } from './ApiSettings'
import type { LocalHttpResponse, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type {
    ICreateFacility,
    IDesign,
    IFacilityGlobalSearch,
    IFacilityName,
    IFacilityOwned,
    IFacilityStatus,
    IFacilityV3,
    IFacilityV4,
    IYard,
} from '@/modules/CORE/facility/interfaces/IFacility'
import type { IFacilityWithBoard } from '@/modules/CORE/interfaces/CoreTypes'
import type { ISearchResponseFacility } from '@/modules/CORE/search/interfaces/ISearch'
import type { AxiosResponse } from 'axios'

export default {
    // * Facility
    facilityByFacilityId: async (facilityId: string): Promise<IFacilityOwned | undefined> => {
        const url = api(`v1/facilities/${facilityId}/`)
        let response: Response | undefined
        let facility: IFacilityOwned | undefined
        try {
            response = await http.get(url)
            facility = await response.json()
        } catch (err) {
            console.error(err)
        }

        return facility
    },
    facilityDetailsV3: async (facilityId: string): Promise<IFacilityV3 | undefined> => {
        const url = api(`v3/facility/${facilityId}/`)
        let response: Response | undefined
        let facility: IFacilityV3 | undefined
        try {
            response = await http.get(url)
            facility = await response.json()
        } catch (err) {
            console.error(err)
        }
        return facility
    },
    facilityDetailsV4: async (facilityId: string): Promise<IFacilityV4 | undefined> => {
        const url = api(`v4/facility/${facilityId}/`)
        let response: Response | undefined
        let facility: IFacilityV4 | undefined
        try {
            response = await http.get(url)
            facility = await response.json()
        } catch (err) {
            console.error(err)
        }
        return facility
    },
    createFacility: async (facilityPayload: ICreateFacility): Promise<APIResponse<ICreateFacility>> => {
        const url = api('v1/facilities/')
        let facility: ICreateFacility | null

        const response: AxiosResponse | undefined = await http.post(url, facilityPayload)

        if (response?.status === 200 || response?.status === 201) {
            facility = await response.data
            return { success: true, message: 'facility created', data: facility }
        } else {
            return { success: false, message: 'failed to create facility', data: null }
        }
    },
    updateFacilityPartial: async (facility: any): Promise<LocalHttpResponse> => {
        const url = api(`v1/facilities/${facility.id}/`)
        const response: AxiosResponse | undefined = await http.patch(url, facility)
        if (response?.status === 200) {
            return { success: true, message: 'facility updated', data: facility }
        } else {
            return { success: false, message: 'failed to update facility', data: facility }
        }
    },
    uploadFacilityImage: async (filename: string, file: any): Promise<APIResponse<any>> => {
        const url = api('v1/uploads/image')

        const formData = new FormData()
        formData.append('image', file)
        formData.append('facility', '')
        formData.append('filename', filename)

        const response: AxiosResponse | undefined = await http.post(url, formData, {})

        if (response?.status === 201 || response?.status === 200) {
            const data = await response.data
            return { success: true, message: 'picture uploaded', data }
        } else {
            return { success: false, message: 'could not upload image', data: null }
        }
    },
    fetchFacilityStatus: async (facilityId: string): Promise<APIResponse<IFacilityStatus | undefined>> => {
        const url = api(`v4/facilitystatus/${facilityId}/`)
        let response: Response | undefined
        let status: IFacilityStatus | undefined
        try {
            response = await http.get(url)
            status = await response.json()
        } catch (err) {
            console.error(err)
            return { success: false, message: 'Failed to fetch facility status', data: null }
        }
        return { success: true, message: 'facility status fetched', data: status }
    },
    getDesignsSearch: async (search?: string): Promise<APIResponse<[IDesign]>> => {
        const url = search ? api(`v1/facilities/design/${search}`) : api('v1/facilities/design')
        let designs: [IDesign] | null = null

        const response: Response | undefined = await http.get(url)
        if (response?.ok) {
            designs = await response.json()
            return { success: true, message: 'designs fetched', data: designs }
        } else {
            return { success: false, message: 'Failed to fetch design search for facility', data: null }
        }
    },
    getYardsSearch: async (search?: string): Promise<APIResponse<[IYard]>> => {
        const url = search ? api(`v1/facilities/yard/${search}`) : api('v1/facilities/yard')
        let yards: [IYard] | null = null

        const response: Response | undefined = await http.get(url)
        if (response?.ok) {
            yards = await response.json()
            return { success: true, message: 'yards fetched', data: yards }
        } else {
            return { success: false, message: 'Failed to fetch yard search for facility', data: null }
        }
    },
    removeFacility: async (facilityId: string): Promise<LocalHttpResponse> => {
        const url = api(`v1/facilities/${facilityId}/`)

        const response: AxiosResponse | undefined = await http.delete(url)

        if (response?.status === 204) {
            return { success: true, message: `Facility ${facilityId} deleted`, data: null }
        } else {
            return { success: false, message: `Failed to delete facility ${facilityId}`, data: null }
        }
    },
    allFacilities: async (): Promise<APIResponse<[IFacilityGlobalSearch]>> => {
        const url = api('v2/all-facilities')

        const response: AxiosResponse | undefined = await http.get(url)

        if (response?.status === 200) {
            const data: IFacilityGlobalSearch[] = (await response.json()) as IFacilityGlobalSearch[]
            return { success: true, message: 'all facilities fetched', data }
        } else {
            return { success: false, message: 'Failed to fetch all facilities', data: [] }
        }
    },
    allFacilitiesV3: async (limit: number = 0): Promise<APIResponse<IFacilityV3[]>> => {
        const url = limit ? api(`v3/all-facilities?limit=${limit}`) : api('v3/all-facilities')

        const response: any | undefined = await http.get(url)

        if (response?.status === 200) {
            const data: IFacilityV3[] = (await response.json()) as IFacilityV3[]
            return { success: true, message: 'all facilities fetched', data }
        } else {
            return { success: false, message: 'Failed to fetch all facilities v3', data: [] }
        }
    },
    allFacilityNames: async (): Promise<APIResponse<[IFacilityName]>> => {
        const url = api('v3/all-facilities?nameOnly=true')

        const response: AxiosResponse | undefined = await http.get(url)

        if (response?.status === 200) {
            const data: IFacilityName[] = (await response.json()) as IFacilityName[]
            return { success: true, message: 'all facilities fetched', data }
        } else {
            return { success: false, message: 'Failed to fetch all facilities v3', data: [] }
        }
    },

    listFacilitiesWithMondayBoards: async (): Promise<APIResponse<any[]>> => {
        const url = api('v2/boardids/')
        const response: AxiosResponse | undefined = await http.get(url)
        if (response?.status === 200) {
            const data: IFacilityWithBoard[] = (await response.json()) as IFacilityWithBoard[]
            return { success: true, message: 'all facilities fetched', data }
        } else {
            return { success: false, message: 'Failed to fetch all facilities v3', data: [] }
        }
    },

    globalFacilitySearch: async (facilityIds: UUIDv4[], searchText: string): Promise<ISearchResponseFacility[]> => {
        const url = api('v1/global-facilities-search')
        let response: AxiosResponse | undefined
        let facilities: ISearchResponseFacility[] = []
        try {
            response = await http.post(url, { facility_ids: facilityIds, search: searchText })
            if (response.status === 200) {
                facilities = response.data
            }
        } catch (err) {
            console.error('Failed to search for facilities.', err)
        }

        return facilities
    },
    globalFacilitySearchRecent: async (company_id: UUIDv4, excludedFacilities: UUIDv4[]): Promise<ISearchResponseFacility[]> => {
        const url = api('v1/global-recent-facilities-search')
        let response: AxiosResponse | undefined
        let facilities: ISearchResponseFacility[] = []
        try {
            response = await http.post(url, { company_id, excluded_facilities: excludedFacilities })
            if (response.status === 200) {
                facilities = response.data
            }
        } catch (err) {
            console.error('Failed to search for recent facilities.', err)
        }

        return facilities
    },
}
