// core imports
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// api endpoints
import { toast } from 'vue3-toastify'

import { Permission } from '@/modules/CORE/interfaces/IUser'
import authApi from '@/api/auth'
import companyApi from '@/api/companies'
import crewApi from '@/api/crew'
import equipmentApi from '@/api/equipment'
import facilityApi from '@/api/facilities'
import monitorApi from '@/api/monitor'
import userAPI from '@/api/users'

import type { IBucketSize } from '@/modules/CONNECT/interfaces/IConnect'

// interfaces
import type { IBucketSize } from '@/modules/CONNECT/interfaces/IConnect'
import type {
    IBackofficeCompany,
    ICompany,
    ICompanyCreate,
    ICompanyReduced,
    ISimpleCompany,
} from '@/modules/CORE/company/interfaces/ICompany'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ILocality } from '@/modules/CORE/company/interfaces/ILocality'
import type { IRegion } from '@/modules/CORE/company/interfaces/IRegion'
import type {
    ICrewMember,
    IFacilityAPIStatusType,
    IFacilityLessor,
    IFacilityListForNotifications,
    IFacilityManufacturer,
    IFacilityName,
    IFacilityOwned,
} from '@/modules/CORE/facility/interfaces/IFacility'
import type { IUserProfile } from '@/modules/CORE/interfaces/CoreTypes'
import type { IUser } from '@/modules/CORE/interfaces/IUser'
import type { IDocument } from '@/modules/MAINTENANCE/documents/interfaces/IDocument'
import type { ICompanyFacilityCountStatus, IWorkorderCountStatus } from '@/modules/MAINTENANCE/workorders/interfaces/IWorkorder'
import type { Router } from 'vue-router'

import { currentLocale } from '@/i18n'
import { assert, isFacilityAdmin, sleep } from '@/utils'
import WebSocketService, { eventType } from '@/WebSocketService'

interface IParams {
    facilities: UUIDv4[]
    dataTypes: string[]
    lookback_period?: string
    bucketSize: IBucketSize
    limit: number
    toTime?: string
    fromTime?: string
}

export const useGlobalStore = defineStore('global', {
    state: () => ({
        // * the best state variable ever
        reRenderKey: 0 as number,

        // * company states
        companies: [] as ICompany[],
        companyList: [] as ISimpleCompany[],
        currentCompany: undefined as ICompanyReduced | undefined,
        currentRegion: undefined as IRegion | undefined,
        facilitiesWithNoLocality: false as boolean,
        facilitiesWithNoRegion: false as boolean,
        companyWorkorderStatus: [] as IWorkorderCountStatus[] | undefined,
        companyDocumentStatus: [] as ICompanyFacilityCountStatus[] | undefined,

        // * facility states
        companyFacilityIds: [] as string[],
        companyLeasedFacilities: undefined as IFacilityLessor[] | undefined,
        companyManufacturedFacilities: undefined as IFacilityManufacturer[] | undefined,
        companyOwnedFacilities: undefined as IFacilityOwned[] | undefined,
        crew: [] as ICrewMember[] | undefined,
        currentFacility: ref(undefined as IFacilityOwned | undefined),
        facilitiesHasLoaded: ref(false as boolean),
        facilityRemarks: [] as any[] | undefined,
        documentsExpired: [] as IDocument[],

        // * equipment states
        moenClassificationList: [] as any[],

        // * loading statess
        loadingCurrentCompany: false as boolean,
        loadingCurrentFacilityIsFinished: false as boolean,

        // * responsitivity states
        sideBarIsOpen: true as boolean,
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        isTinyScreenSize: false as boolean,
        isMobileScreenSize: false as boolean, // this one is reactive (via event listener in App.vue)
        isTabletScreenSize: false as boolean, // this one is reactive (via event listener in App.vue)
        isMobileOrTabletScreenSize: false as boolean, // this one is reactive (via event listener in App.vue)
        isDesktopScreenSize: true as boolean, // this one is reactive (via event listener in App.vue)

        // * user states
        currentUser: null as IUser | null,
        currentProfile: null as IUserProfile | null,

        // * search states
        // a way for global search to trigger a refresh of the vue components even when the route hasnt changed
        globalSearchPulse: 0 as number,

        // * websocket & notification states
        notifications: [] as any[], // TODO: make interface
        unseenNotificationsCount: 0 as number,
        newNotifications: false as boolean, // makes the bell icon red
        websocketService: null as WebSocketService | null,
        allFacilityNames: [] as IFacilityName[],
    }),
    getters: {
        // * user getters
        userIsAdminOrSuperUser: (state: any): boolean => {
            if (!state.currentUser) return false
            if (state.currentUser.is_superuser) return true

            if (state.loadingCurrentFacilityIsFinished && state.currentFacility) {
                if (isFacilityAdmin(state.currentFacility)) return true
            }
            return false
        },

        // * facility getters
        currentFacilityIsReady: (state: any): boolean => {
            // loadingCurrentFacilityIsFinished is true
            // currentFacility is not undefined
            return state.loadingCurrentFacilityIsFinished && state.currentFacility
        },

        // * company getters
        currentCompanyIsReady: (state: any): boolean => {
            return state.loadingCurrentCompany === false && state.currentCompany
        },
    },

    actions: {
        // * generic actions
        toggleSideNavigation(): void {
            this.sideBarIsOpen = !this.sideBarIsOpen
            localStorage.setItem('sideBarIsOpen', JSON.stringify(this.sideBarIsOpen))
        },
        logout(): void {
            this.currentUser = null
            this.websocketService?.close()
        },

        // * search actions
        triggerGlobalSearchPulse(): void {
            this.globalSearchPulse++
        },
        async switchToCorrectCompanyIfLessor(): Promise<void> {
            if (this.currentUser?.companies?.length && this.currentFacility?.lessors?.[0]?.id === this.currentUser.companies[0]) {
                await this.fetchCompanyById(this.currentUser.companies[0])
            }
        },
        // * auth & context actions
        async loginActionsPostAuthentication(): Promise<void> {
            // fetch user and put it in state
            const user: IUser | null = await this.fetchUser()
            if (!user) {
                toast.error('No user found - contact Moen Marin')
                return
            }

            // save username in localstorage so it can be reached by webapp-stats in router middleware
            try {
                localStorage.setItem('mlink-username', user.email)
            } catch (e: any) {
                console.warn('Could not save username in localstorage', e)
                localStorage.setItem('mlink-username', 'unknown')
            }

            // set correct company and facility context for the user
            const context = await authApi.noContext()
            if (!context) {
                console.error('Could not establish context')
                return
            }
            await this.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
        },

        async adminPageContextHandler(user: IUser | null, route: Router): Promise<boolean> {
            /**
             * Checks if the current user has access to the admin page.
             * If the user is not logged in, they will be redirected to the login page.
             * Superusers always have access.
             * If the user has no admin companies, they will not be allowed to enter the page.
             * If the company ID from the URL does not exist in the admin companies, the user is not allowed to enter the page.
             * If the company ID from the URL exists and matches an ID in the admin companies,
             * the user is allowed to continue to the admin page.
             *
             * @param { IUser|null } user - The current user.
             * @param { Router } route - The router instance.
             * @returns A promise that resolves when the access check is complete.
             */
            if (!user) {
                route.push({ name: 'login-page' })
                return false
            }

            if (user.is_superuser) {
                return true
            }
            if (user.company_ids_user_is_admin_in && user.company_ids_user_is_admin_in.length === 0) {
                route.push({ name: 'not-allowed' })
                console.warn('User has no companies with admin privileges')
                return false
            }

            const companyIdToLoad: UUIDv4 = route.currentRoute.value.params.companyId as UUIDv4
            if (!companyIdToLoad) {
                console.warn('No company ID in URL')
                return false
            }

            const userHasAccess = user.company_ids_user_is_admin_in ? user.company_ids_user_is_admin_in.includes(companyIdToLoad) : false
            if (!userHasAccess) {
                console.warn('User does not have access to company')
                route.push({ name: 'not-allowed' })
                return false
            }
            return true
        },

        async superUserPageContextHandler(user: IUser | null, route: Router): Promise<boolean> {
            /**
             * Checks if the current user has access to the superuser page.
             * If the user is not logged in, they will be redirected to the login page.
             * Superusers always have access.
             *
             * @param { IUser | null }    user - The current user.
             * @param { Router }        route - The router instance.
             * @returns A promise that resolves when the access check is complete.
             */

            const router = useRouter()

            if (!user) {
                router.push({ name: 'login-page' })
                return false
            }

            if (!user.is_superuser) {
                route.push({ name: 'not-allowed' })
                return false
            }
            return true
        },

        async waitForContext(timeoutDurationInSeconds: number = 20): Promise<boolean> {
            /*
                this method is waiting for both "currentCompany" and "currentFacility" to be available in state

                so, instead of doing;
                await this.waitForCurrentCompany()
                await this.waitForCurrentFacility()

                we can use this method to wait for both at the same time;
                await this.waitForContext()

            */
            const isReadyFunction =
                this.currentFacilityIsReady !== undefined ? () => this.currentFacilityIsReady : () => this.currentCompanyIsReady
            const intervalDuration = 20 // milliseconds
            const iterations = (timeoutDurationInSeconds * 1000) / intervalDuration

            for (let i = 0; i < iterations; i++) {
                if (isReadyFunction()) return true
                await sleep(intervalDuration)
            }
            return false
        },

        // * company actions
        async createCompany(payload: ICompanyCreate): Promise<ICompany | undefined> {
            const company: ICompany | undefined = await companyApi.createCompany(payload)
            return company
        },
        async waitForCurrentCompany(timeoutDurationInSeconds: number = 20): Promise<boolean> {
            const intervalDuration = 50 // 50 milliseconds
            const iterations = (timeoutDurationInSeconds * 1000) / intervalDuration

            for (let i = 0; i < iterations; i++) {
                if (this.currentCompanyIsReady) return true
                await sleep(intervalDuration)
            }

            return false
        },
        async waitForWebsocketServer(timeoutDurationInSeconds: number = 20): Promise<boolean> {
            const intervalDuration = 50 // 50 milliseconds
            const iterations = (timeoutDurationInSeconds * 1000) / intervalDuration

            for (let i = 0; i < iterations; i++) {
                if (this.websocketService) {
                    return true
                }
                await sleep(intervalDuration)
            }

            return false
        },
        async fetchCompanyById(companyId: UUIDv4): Promise<ICompanyReduced | undefined> {
            this.loadingCurrentCompany = true
            const company = await companyApi.fetchCompanyByCompanyIdV4(companyId)
            if (company) {
                this.currentCompany = company
                this.loadingCurrentCompany = false
                this.fetchUser(this.currentCompany?.id)
                return company
            } else {
                console.warn('Error while fetching company!')
                return undefined
            }
        },
        async fetchCompanyFacilityUUIDs(companyId: UUIDv4) {
            const facilities = await companyApi.fetchFacilityUUIDsByCompanyId(companyId)
            return facilities
        },
        async setCurrentCompanyAndFacility(companyId: UUIDv4, facilityId: UUIDv4): Promise<void> {
            // set current company
            await this.fetchCompanyById(companyId)
            // set current facility
            await this.getOrFetchCurrentFacility(facilityId)
        },
        async setCurrentCompanyAndUnsetFacility(companyId: UUIDv4): Promise<void> {
            // set current company
            await this.fetchCompanyById(companyId)

            // unset current facility
            this.currentFacility = undefined
        },

        // * facility actions
        async waitForCurrentFacility(timeoutDurationInSeconds: number = 20): Promise<boolean> {
            const intervalDuration = 50 // 50 milliseconds
            const iterations = (timeoutDurationInSeconds * 1000) / intervalDuration

            for (let i = 0; i < iterations; i++) {
                if (this.currentFacilityIsReady) return true
                await sleep(intervalDuration)
            }

            return false
        },
        setCurrentFacility(facility: IFacilityOwned): IFacilityOwned {
            this.reRenderKey++
            this.currentFacility = facility
            this.loadingCurrentFacilityIsFinished = true
            return this.currentFacility
        },
        async clearRecentFacility() {
            // clear user.recent_facility in the backend
            return userAPI.clearRecentFacility()
        },
        async fetchFacilityStatusByTypeAndCompanyId(
            companyId: UUIDv4,
            type: IFacilityAPIStatusType,
        ): Promise<ICompanyFacilityCountStatus[] | IWorkorderCountStatus[] | undefined> {
            const status = await companyApi.fetchFacilityStatusByType(companyId, type)
            return status
        },
        async setCurrentFacilityByFacilityId(facilityId: UUIDv4): Promise<void> {
            const facilityObject = await this.getOrFetchCurrentFacility(facilityId)
            if (!facilityObject) {
                console.warn('Could not set facility by facility id')
                return
            }

            this.setCurrentFacility(facilityObject)
        },
        async getOrFetchCurrentFacility(facilityId: string): Promise<IFacilityOwned | null> {
            if (!facilityId) return null

            this.resetFacilityLoading()
            const facility = await facilityApi.facilityByFacilityId(facilityId)

            if (facility) {
                let getLangFromLocale = currentLocale.value
                if (getLangFromLocale === 'no') {
                    // @ts-expect-error-next-line
                    getLangFromLocale = 'nb'
                }
                // fetch moenClassificationList for the facility
                const classificationSystem = facility.classification_system || 'sfi'
                const moenClassificationList = await equipmentApi.fetchFlatClassificationsByLocaleAndSystem(
                    getLangFromLocale,
                    classificationSystem,
                )
                // put classifications in global store (i.e. global state) --> global.ts
                if (moenClassificationList && moenClassificationList.length > 0) {
                    this.moenClassificationList = moenClassificationList
                }
                return this.setCurrentFacility(facility) as IFacilityOwned
            } else {
                console.warn('An error occurde while fetching facility')
                return null
            }
        },
        resetFacilityLoading() {
            this.loadingCurrentFacilityIsFinished = false
        },

        // * crew actions
        async fetchCrewByFacilityId(facilityId: UUIDv4): Promise<ICrewMember[] | undefined> {
            if (!facilityId) {
                console.warn('No facility id provided')
                return
            }
            return crewApi.fetchCrewByFacilityId(facilityId)
        },
        async checkInCrewMember(facilityId: UUIDv4, crewMemberId: UUIDv4, role: string): Promise<boolean> {
            const response = await crewApi.checkInCrewMember(facilityId, crewMemberId, role)
            return response.success
        },
        async checkOutCrewMember(crewMemberId: UUIDv4): Promise<boolean> {
            const response = await crewApi.checkOutCrewMember(crewMemberId)
            return response.success
        },

        // fetch initial notification data and setup websocket connection for future notifications
        async setupNotifications(): Promise<void> {
            const allFacilityNames = await facilityApi.allFacilityNames()

            if (allFacilityNames.data) {
                allFacilityNames.data.forEach((facility: IFacilityName) => {
                    this.allFacilityNames.push(facility)
                })
            }

            userAPI.fetchUserNotifications(10).then((response) => {
                const notifications = response.notifications
                const count = response.unseen_count || 0
                if (notifications) this.notifications = notifications
                if (count) this.unseenNotificationsCount = count
            })

            this.setupWebsocketConnection()
        },
        async fetchAllNotifications(): Promise<void> {
            userAPI.fetchUserNotifications(1000).then((response) => {
                const notifications = response.notifications
                const count = response.unseen_count || 0
                if (notifications) this.notifications = notifications
                if (count) this.unseenNotificationsCount = count
            })
        },
        // * user actions
        async fetchUser(companyId: UUIDv4 | undefined = undefined): Promise<IUser | null> {
            const user = await authApi.fetchUserIfAuthenticated(companyId)
            if (user) {
                this.currentUser = user

                return user
            } else {
                return null
            }
        },
        async fetchCompaniesByUser(): Promise<ISimpleCompany[]> {
            const companies = await companyApi.fetchSimpleCompaniesByCurrentUser()
            if (companies) {
                return companies
            } else {
                return []
            }
        },
        async fetchBackofficeCompanies(): Promise<IBackofficeCompany[]> {
            return companyApi.fetchBackofficeCompanies()
        },
        async fetchUserProfile(profileId: number): Promise<IUserProfile | null> {
            const profile = await authApi.fetchUserProfileById(profileId)
            this.currentProfile = profile
            return profile
        },
        // * region & locality actions
        setCurrentRegion(region: IRegion): void {
            if (this.currentCompany) {
                this.currentRegion = region
            } else {
                console.warn('No current company set')
            }
        },
        async createRegion(region: IRegion): Promise<IRegion | undefined> {
            const createdRegion = await companyApi.createRegion(region)

            if (createdRegion) {
                this.currentCompany?.regions.push(createdRegion)
            }
            return createdRegion
        },

        async deleteRegion(region: IRegion): Promise<boolean> {
            const status = await companyApi.deleteRegion(region)

            if (status) {
                const index = this.currentCompany?.regions.findIndex((r) => r.id === region.id)
                if (index !== undefined && index !== -1) {
                    this.currentCompany?.regions.splice(index, 1)
                }
            }

            return status
        },
        async editRegion(region: IRegion): Promise<boolean> {
            const status = await companyApi.editRegion(region)

            if (status) {
                const index = this.currentCompany?.regions.findIndex((r) => r.id === region.id)
                if (index !== undefined && index !== -1) {
                    this.currentCompany?.regions.splice(index, 1, region)
                }
            }

            return true
        },
        async createLocality(locality: ILocality, region: IRegion): Promise<any> {
            const responseBody = await companyApi.createLocality(locality, region)

            if (responseBody) {
                region.localities?.push(responseBody)
            }

            return responseBody
        },
        async deleteLocality(locality: ILocality, region: IRegion): Promise<boolean> {
            const status = await companyApi.deleteLocality(locality, region)

            if (status) {
                const index = region?.localities?.findIndex((l) => l.id === locality.id)
                if (index !== undefined && index !== -1) {
                    region?.localities?.splice(index, 1)
                }
            }

            return status
        },
        async editLocality(locality: ILocality, region: IRegion): Promise<boolean> {
            const status = await companyApi.editLocality(locality, region)

            if (status) {
                const index = region.localities?.findIndex((l) => l.id === locality.id)
                if (index !== undefined && index !== -1) {
                    region.localities?.splice(index, 1, locality)
                }
            }

            return status
        },

        // * websocket & notification actions
        setupWebsocketConnection() {
            if (this.currentUser) {
                this.websocketService = new WebSocketService(this.currentUser.id)

                this.websocketService?.addListener((data) => {
                    if (data.ws_id) {
                        // if the notification is already in the list, remove it and add the new one
                        this.addUserEventsFromWebSocket(data)
                    }
                }, eventType.UserNotification)
            }
        },
        async setNotificationsAsSeen(wsIDs: UUIDv4[]): Promise<boolean> {
            const status = await userAPI.markUserNotificationAsSeen(wsIDs)

            if (status) {
                for (const wsID of wsIDs) {
                    const index = this.notifications.findIndex((n: any) => n.ws_id === wsID)
                    if (index !== undefined && index !== -1) {
                        this.notifications[index].notification_seen = true
                    }
                }
                if (wsIDs.length >= this.unseenNotificationsCount) {
                    this.unseenNotificationsCount -= wsIDs.length
                } else {
                    this.unseenNotificationsCount = 0
                }
            }
            return status
        },
        async setUserNotificationsAsOpened(wsIDs: UUIDv4[]): Promise<boolean> {
            const status = await userAPI.markUserNotificationAsOpened(wsIDs)

            if (status) {
                for (const wsID of wsIDs) {
                    const index = this.notifications.findIndex((n: any) => n.ws_id === wsID)
                    if (index !== undefined && index !== -1) {
                        this.notifications[index].notification_seen = true
                        this.notifications[index].notification_opened = true
                    }
                }
            }
            return status
        },
        async getUnseenNotificationCountForFacility(facilityId: UUIDv4): Promise<number> {
            console.log('getUnseenNotificationCountForFacility', facilityId)
            const count = await userAPI.getUnseenNotificationCountForFacility(facilityId)
            return count
        },
        async getFacilityListForNotifications(companyId: UUIDv4): Promise<IFacilityListForNotifications[]> {
            const facilities = (await userAPI.getFacilityListForNotifications(companyId)) as IFacilityListForNotifications[]
            return facilities
        },
        addUserEventsFromAPI(data: any) {
            // If the notification already exists, remove it as we have a fresh one from db
            const exists = this.notifications.find((n: any) => n.id === data.id)
            if (exists) this.notifications.splice(this.notifications.indexOf(exists), 1)

            data.forEach((n: any) => {
                this.notifications.push(n)
            })
        },
        addUserEventsFromWebSocket(data: any) {
            // (failsafe)In the rare event where the ws-message comes after the database fetch, we dont have to add it.
            const exists = this.notifications.find((n: any) => n.ws_id === data.ws_id)

            // add notification at the start of array
            if (exists === undefined || !exists) {
                this.notifications.unshift(data)
                if (this.unseenNotificationsCount < 0) this.unseenNotificationsCount = 0
                this.unseenNotificationsCount++
            }

            // remove the oldest notification if we have more than 10
            if (this.notifications.length > 10) {
                this.notifications.pop()
            }
            this.newNotifications = true
        },

        // CONNECT
        async fetchHistoricalConnectData(
            facilityId: UUIDv4,
            combined_id: string,
            lookbackPeriod: string,
            bucketSize: IBucketSize,
            fromTime: string,
            toTime: string,
        ): Promise<any> {
            const params: IParams = {
                facilities: [facilityId],
                dataTypes: [combined_id],
                lookback_period: lookbackPeriod, // what other options are there?
                bucketSize, // what other options are there?
                limit: 10000,
            }

            if (fromTime && toTime) {
                delete params.lookback_period
                params.fromTime = fromTime
                params.toTime = toTime
            }
            return monitorApi.retrieveMonitorDataseries(params)
        },
        async fetchHistoricalConnectDataForEDashboard(
            facilityId: UUIDv4,
            combined_ids: string[],
            bucketSize: IBucketSize,
            fromTime: string,
            toTime: string,
        ): Promise<any> {
            const params: IParams = {
                facilities: [facilityId],
                dataTypes: combined_ids,
                bucketSize, // what other options are there?
                limit: 10000,
                fromTime,
                toTime,
            }
            return monitorApi.retrieveMonitorDataseries(params)
        },
        async fetchTrajectories(facilities: UUIDv4[], fromTime: string, toTime: string, bucketSize: IBucketSize): Promise<any> {
            const PARAMS: IParams = {
                facilities,
                dataTypes: ['34__', '35__'],
                bucketSize,
                fromTime,
                toTime,
                limit: 10000,
            }
            return monitorApi.retrieveMonitorDataseries(PARAMS)
        },
        async fetchPositionsTimeline(facilities: UUIDv4[], fromTime: string, toTime: string, limit: number = 10000): Promise<any> {
            interface IPositionsTimelineParams {
                facilities: UUIDv4[]
                fromTime: string
                toTime: string
                limit?: number
            }

            const PARAMS: IPositionsTimelineParams = {
                facilities,
                fromTime,
                toTime,
                limit,
            }

            return monitorApi.retrievePositionsTimeline(PARAMS)
        },
        async fetchConnectFuelTotalData(facilityIds: UUIDv4[], lookbackPeriod: string, fromTime: string, toTime: string): Promise<any> {
            interface IParams {
                facilities: UUIDv4[]
                lookback_period?: string
                toTime?: string
                fromTime?: string
            }

            const params: IParams = {
                facilities: facilityIds,
                lookback_period: lookbackPeriod, // what other options are there?
            }

            if (fromTime && toTime) {
                delete params.lookback_period
                params.fromTime = fromTime
                params.toTime = toTime
            }
            return monitorApi.retrieveFuelConsumption(params, 'daily')
        },

        async fetchConnectDistanceTravelledData(
            facilityIds: UUIDv4[],
            lookbackPeriod: string,
            fromTime: string,
            toTime: string,
        ): Promise<any> {
            interface IParams {
                facilities: UUIDv4[]
                lookback_period?: string
                toTime?: string
                fromTime?: string
            }

            const params: IParams = {
                facilities: facilityIds,
                lookback_period: lookbackPeriod, // what other options are there?
            }

            if (fromTime && toTime) {
                delete params.lookback_period
                params.fromTime = fromTime
                params.toTime = toTime
            }

            // TODO: Detect if "fromTime" and "toTime" is the same date!
            //     If it is, then we should use "hourly" instead of "daily"
            return monitorApi.retrieveDistanceTravelled(params, 'daily')
        },
    },
})
