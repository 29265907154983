import { Axios } from 'axios'
import { api } from './ApiSettings'
import http from './http'

import type { AxiosResponse } from 'axios'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IConnectData } from '@/modules/CORE/interfaces/CoreTypes'

export default {
    retrieveLastValuesForFacility: async (facilityId: string, since = '2 hours') => {
        if (!facilityId) return { response: { ok: false }, data: [] }

        const url = api(`v2/smart-support/timeseries/latest/${facilityId}?since=${since}`)
        const errorMessage = 'Failed to retrieve last values for facility'
        let response = null
        let data = []
        try {
            response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }
        return { response, data }
    },
    retrieveLastValuesForFacilityV3: async (facilityId: UUIDv4): Promise<{ [key: string]: IConnectData }> => {
        if (!facilityId) return {}

        const url = api(`v3/smart-support/timeseries/latest/${facilityId}`)
        const errorMessage = 'Failed to retrieve last values for facility (v3)'
        let response = null
        let data: { [key: string]: IConnectData } = {}
        try {
            response = await http.get(url)
            if (response.ok) {
                const rawData = await response.json()
                data = rawData.data
            }
        } catch (err) {
            console.error(errorMessage, err)
        }
        return data
    },
    retrieveTodayValuesForFacility: async (facilityId: string) => {
        if (!facilityId) return { response: { ok: false }, data: [] }

        const url = api(`v2/smart-support/timeseries/today/${facilityId}`)
        const errorMessage = 'Failed to retrieve today values for facility'
        let response = null
        let data = []
        try {
            response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }
        return { response, data }
    },
    retrieveMonitorDataseries: async (params: any) => {
        const url = api('v2/smart-support/timeseries/mean')
        const errorMessage = 'Failed to retrieve monitor dataseries'
        let response = null
        let data = null
        try {
            response = await http.post(url, JSON.stringify(params))
            if (response.status === 200) {
                data = response.data
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return { response, data }
    },
    retrievePositionsTimeline: async (params: any) => {
        const URL = api('v2/smart-support/timeseries/positions/timeline')
        const ERROR_MESSAGE = 'Failed to retrieve positions timeline'
        let response = null
        let data = null
        try {
            response = await http.post(URL, JSON.stringify(params))
            if (response.status === 200) {
                data = response.data
            }
        } catch (err) {
            console.error(ERROR_MESSAGE, err)
        }
        return { response, data }
    },
    retrieveFuelConsumption: async (params: any, type: string) => {
        const url = api(`v2/smart-support/timeseries/fuel/${type}`)
        const errorMessage = `Failed to retrieve fuel consumption dataseries for type: ${type}`
        let response = null
        let data = null
        try {
            response = await http.post(url, JSON.stringify(params))
            if (response.status === 200) {
                data = response.data
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return { response, data }
    },
    retrieveDistanceTravelled: async (params: any, type: string) => {
        const url = api(`v2/smart-support/timeseries/distance/${type}`)
        const errorMessage = `Failed to retrieve distance travelled dataseries for type:  ${type}`
        let response = null
        let data = null
        try {
            response = await http.post(url, JSON.stringify(params))
            if (response.status === 200) {
                data = response.data
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return { response, data }
    },
    fetchEquipmentLocationTable: async () => {
        const url = api('v4/smart-support/equipment-location')
        const errorMessage = 'Failed to retrieve equipment location table'
        let data = []

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
    fetchSignalStandardTable: async () => {
        const url = api('v4/smart-support/signal-standard')
        const errorMessage = 'Failed to retrieve signal standard table'
        let data = []

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
    fetchSensorKeysTable: async () => {
        const url = api('v4/smart-support/sensor-keys')
        const errorMessage = 'Failed to retrieve sensor keys table'
        let data = []

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
    fetchSensorsKeysForASignal: async (signalId: number) => {
        const url = api(`v4/smart-support/sensor-keys/${signalId}`)
        const errorMessage = `Failed to retrieve sensor keys for signal: ${signalId}`
        let data = null

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage)
        }

        return data
    },
    fetchConnectStatus: async () => {
        const url = api('v2/smart-support/connect/status')
        const errorMessage = 'Failed to retrieve connect status'
        let data = null

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },

    fetchTailscaleDevices: async () => {
        const url = api('v4/connect/tailscale/devices')
        const errorMessage = 'Failed to retrieve tailscale devices'
        let data = null

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },

    fetchMQTTCredentials: async () => {
        const url = api('v2/smart-support/mqtt/credentials')
        const errorMessage = 'Failed to retrieve mqtt credentials'
        let data = null

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
    fetchSourceSensorsAndSignals: async (facilityId: UUIDv4 | null) => {
        const base = 'v2/smart-support/source/sensors-and-signals'
        const url = api(facilityId ? `${base}/${facilityId}` : `${base}`)
        const errorMessage = 'Failed to retrieve source sensors and signals'
        let data = null

        try {
            const response = await http.get(url)
            if (response.ok) {
                data = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
    updateSensorKeySignalStandard: async (params: any) => {
        const url = api('v2/smart-support/source/sensors-and-signals')
        const errorMessage = 'Failed to update sensor key signal standard'
        let response = null

        try {
            response = await http.put(url, JSON.stringify(params))
        } catch (err) {
            console.error(errorMessage, err)
        }

        return response
    },

    deleteMQTTCredentials: async (buildNrLowercase: string): Promise<AxiosResponse | undefined> => {
        const url = api(`v2/smart-support/mqtt/credentials/${buildNrLowercase}`)
        const errorMessage = 'Failed to delete mqtt credentials'

        try {
            return http.delete(url)
        } catch (err) {
            console.error(errorMessage, err)
            return undefined
        }
    },

    updateMQTTCredentials: async (params: any) => {
        if (!params) return null

        const url = api(`v2/smart-support/mqtt/credentials/${params.username}`)
        const errorMessage = 'Failed to update mqtt credentials'
        let data = null

        try {
            const response = await http.put(url, JSON.stringify(params))
            if (response) {
                data = response.data
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
    createMQTTCredentials: async (buildNrLowercase: string) => {
        if (!buildNrLowercase) {
            console.error('Failed to create mqtt credentials: buildNrLowercase is missing')
            return null
        }

        const params = {
            username: buildNrLowercase,
            allow: 1,
        }

        const url = api(`v2/smart-support/mqtt/credentials/${buildNrLowercase}`)
        const errorMessage = 'Failed to create mqtt credentials'
        let data = null

        try {
            const response = await http.post(url, JSON.stringify(params))
            if (response) {
                data = response.data
            }
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },

    generateNewMQTTPassword: async () => {
        const url = api('v2/smart-support/mqtt/gen-password')
        const errorMessage = 'Failed to generate new mqtt password'
        let data = null
        try {
            const response = await http.get(url)
            data = await response.json()
        } catch (err) {
            console.error(errorMessage, err)
        }

        return data
    },
}
